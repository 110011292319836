import { mapState, mapActions } from 'vuex';

import KnAddress from '../../../shared/components/KnAddress.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnContactInfo from '../../../shared/components/KnContactInfo.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnPersonalInfo from '../../../shared/components/KnPersonalInfo.vue';
import KnSchoolInfo from '../../../shared/components/KnSchoolInfo.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnTaxInfo from '../../../shared/components/KnTaxInfo/KnTaxInfo.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import { getItem, postItem } from '../../../../api/api-methods';

import { addressUtilsMixin } from '../../../shared/mixins/addressUtilsMixin';
import { arrayUtilsMixin } from '../../../shared/mixins/arrayUtilsMixin';
import { dateUtilsMixin } from '../../../shared/mixins/dateUtilsMixin';
import { generalFetchingMixin } from '../../../shared/mixins/generalFetchingMixin';
import { generalRepoMixin } from '../../../shared/mixins/generalRepoMixin';
import { utilsMixin } from '../../../shared/mixins/utilsMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  biuldStudentGroup,
  fetchInvoiceUsage,
  fetchPostAssignmentTuition,
  fetchPostUpdateChangeTuition,
  postStudent,
  putStudent,
} from '../../helpers/utilsStudent';
import {
  buildStudentAddress,
  buildStudentPersonalInfo,
  buildStudentTutor,
  fetchPostRegisterTutor,
  findTutor,
  /*postTutor,*/ putTutor,
} from '../../helpers/utilsTutor';
import { ACTIONS_GROUP, BASE_FINANCIAL_STATUS } from '../../helpers/states';
import { WildcardFetcher } from '../../mixins/WildcardFetcherMixin';
import { StudentUtils } from '../../mixins/StudentUtilsMixin';
import { fetchPostDebit } from '../../../pos/helpers/debitUtils';
import {
  canAdd,
  canChange,
  canDelete,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
import { fetchPostAssignStudentsToGroup, handleStudentGroupChange } from '../../../employees/helpers/actionsGroupsOptions';
import KnModalStudentUnenroll from '../KnModalStudentUnenroll/KnModalStudentUnenroll.vue';
import { calculateDebtsObject, generateSchoolYearDebts } from '../../../shared/helpers/tuitionAndFeesPerStudent';
import { financialStatusUtilsMixin } from '../../mixins/FinancialStatusUtilsMixin';
import { deleteAddress } from '../../../configuration/helpers/KnGroupsOptions';

export default {
  components: {
    KnTaxInfo,
    KnPersonalInfo,
    KnCheckBox,
    KnAddress,
    KnSchoolInfo,
    KnFormActionButtons,
    KnFormSubtitle,
    KnContactInfo,
    KnLocalAlert,
    KnTabs,
    KnBackToTopButton,
    KnFormTitle,
    KnFormNoteOfMandatory,
    KnSelect,
    KnModalStudentUnenroll,
  },
  mixins: [
    addressUtilsMixin,
    arrayUtilsMixin,
    dateUtilsMixin,
    generalFetchingMixin,
    generalRepoMixin,
    utilsMixin,
    financialStatusUtilsMixin,
    validationFormMixin,
    WildcardFetcher,
    StudentUtils,
  ],
  props: {
  },
  data() {
    return {
      valueDeterminate: 33,
      routerName: 'Alumnos',
      resource: 'alumno',
      row: null,
      openTuition: false,
      schoolsYearArr: [],
      schoolYear: null,
      tabs: [
        { name: 'Datos del tutor', value: 33 },
        { name: 'Datos del alumno', value: 66 },
        { name: 'Datos fiscales', value: 100 },
      ],
      loading: false,
      studentId: null,
      tutorId: null,
      isNewTutor: true,
      showUnenrollModal: false,
      studentPersonalInfo: {
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad: '',
        institucion_educativa: null,
        numero_credencial_alumno: '',
        nivel_educativo: '',
        curp: null,
        dateRange: {
          from: null,
          to: null,
        },
      },
      studentAddress: {
        estado: null,
        ciudad: '',
        municipio: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      },
      tutorPersonalInfo: {
        curp: null,
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad: '',
        telefono_movil: null,
        email: null,
      },
      tutorTaxInfo: {
        tax_id: null,
        nombre_o_razon_social: null,
        uso_factura: null,
        regimen_fiscal: null,
        forma_pago: null,
        lugar_expedicion: null,
        condiciones_pago: null,
        metodo_pago: null,
        moneda: null,
        tipo_cfdi: null,
      },
      tutorAddress: {
        estado: null,
        ciudad: '',
        municipio: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      },
      isAssigningOrReassigning: false,
      originalTaxInfo: {},
      isEditMode: false,
      showCheckboxAddress: true,
      isAddressCheckboxChecked: true,
      backupStudentAddress: {},
      loadingTutor: false,
      initialStudentInfo: null,
      initialTutorInfo: null,
      checkInitialDebts: true,
      wildcardStudentDebts: [],
      usernameTutor: '',
      passwordTutor: '',
      usernameStudent: '',
      passwordStudent: '',

      tutor: null,
      tutorsArr: [],
      studyPlans: [],
      showGroupActions: false,
      fillFromSearch: false,
      fillStudentFromSearch: false,
      groupActions: [
        // {
        //   text: 'Reasignar - mismo ciclo escolar/corrección de colegiatura',
        //   value: ACTIONS_GROUP.REASSIGN,
        //   hasPermissions: () => canAdd('cambiogrupo') || canChange('adeudo'),
        // },
        {
          text: 'Reasignar - (Nuevo ciclo escolar)',
          value: ACTIONS_GROUP.PASS,
          hasPermissions: () => canAdd('cambiogrupo') || canAdd('adeudo'),
        },
        {
          text: 'Desasignar',
          value: ACTIONS_GROUP.UNASSIGN,
          hasPermissions: () => canAdd('cambiogrupo') || canDelete('adeudo'),
        },
      ],
      selectedGroupAction: null,
      groups: [],
      tuition: [],
      promptPaymentDiscounts: [],
      penalties: [],
      debtStatus: [],
      selectedGroup: null,
      studentDebts: [],
      currentStudent: null,
      isEditing: false,
      useGenericInfo: true,
      loadingGroupChange: false,
      loadingGroupInfo: false,
      tuitionStartDate: undefined,
      tuitionEndDate: undefined
    };
  },
  watch: {
    computedCheck(newVal) {
      this.isAddressCheckboxChecked = newVal;
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'currentInstitution']),
    title() {
      return this.currentStudent === null ? 'Agregar alumno' : 'Editar alumno';
    },
    fechaInicio() {
      return this.selectedGroup && this.selectedGroup.ciclo_escolar
        ? this.selectedGroup.ciclo_escolar.fecha_inicio
        : 'No disponible';
    },
    fechaFin() {
      return this.selectedGroup && this.selectedGroup.ciclo_escolar
        ? this.selectedGroup.ciclo_escolar.fecha_fin
        : 'No disponible';
    },
    isNewMode() {
      return this.currentStudent === null;
    },
    getIsAddressCheckboxChecked() {
      return this.isAddressCheckboxChecked
    },
    computedAlertMessage() {
      if (this.isNewMode) {
        return 'Nota importante: La dirección del alumno se establecerá automáticamente como la misma que la del tutor. Si necesitas ingresar una dirección diferente para el alumno, desmarca esta opción.';
      }
      return "Nota: La dirección del alumno está actualmente configurada para coincidir con la del tutor. Puedes modificar esta configuración en la sección del tutor, o agregar una nueva dirección para el alumno.";
    },
    computedAlertType() {
      if (this.isNewMode)
        return 'info'
      else 'success';
    },


    successAlertText() {
      return this.isNewMode
        ? 'Alumno registrado con exito!'
        : 'Alumno actualizado con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    assignGroupText() {
      return !this.hasGroup ? 'Asignar grupo' : 'Grupo';
    },
    hasGroup() {
      return (
        this.studentPersonalInfo.grupo !== undefined &&
        this.studentPersonalInfo.grupo !== null
      );
    },
    invalidFields() {
      const studentFields = [
        this.studentPersonalInfo.primer_nombre,
        this.studentPersonalInfo.apellido_paterno,
        this.studentPersonalInfo.apellido_materno,
        this.studentPersonalInfo.genero,
        this.studentPersonalInfo.fecha_nacimiento,
        this.studentPersonalInfo.edad,
        this.studentPersonalInfo.nivel_educativo
      ];

      const tutorFields = [
        this.tutorPersonalInfo.primer_nombre,
        this.tutorPersonalInfo.apellido_paterno,
        this.tutorPersonalInfo.apellido_materno,
        this.tutorPersonalInfo.genero,
        this.tutorPersonalInfo.fecha_nacimiento,
        this.tutorPersonalInfo.edad,
        this.tutorPersonalInfo.telefono_movil,
        this.tutorPersonalInfo.email,
      ];

      const addressFields = [
        this.tutorAddress.estado,
        this.tutorAddress.codigo_postal,
        this.tutorAddress.colonia,
        this.tutorAddress.calle,
        this.tutorAddress.numero_exterior,
      ];
      const taxFields = [
        this.tutorTaxInfo.tax_id,
        this.tutorTaxInfo.nombre_o_razon_social,
        this.tutorTaxInfo.uso_factura,
      ];

      const allFields = [...studentFields, ...tutorFields, ...addressFields, ...taxFields];
      return allFields.some(field => field === null || field === '');
    },
    showAssignGroup() {
      return (
        !this.hasGroup ||
        (this.selectedGroupAction !== null &&
          this.selectedGroupAction === ACTIONS_GROUP.REASSIGN)
      );
    },

    schoolCycleText() {
      return this.selectedGroup !== null
        ? `${this.selectedGroup.ciclo_escolar.fecha_inicio} - ${this.selectedGroup.ciclo_escolar.fecha_fin}`
        : '';
    },
    tuitionText() {
      // Verifica que currentStudent y currentStudent.colegiatura existan
      if (this.currentStudent && this.currentStudent.colegiatura) {
        return `${this.currentStudent.colegiatura.nombre_colegiatura} - $${this.currentStudent.colegiatura.monto}`;
      } else {
        return 'Sin asignar';
      }
    },
    tuitionCheckLabel() {
      if (!this.currentStudent) {
        return 'Asignar colegiatura';
      }
      return this.currentStudent && this.currentStudent.colegiatura
        ? 'Actualizar colegiatura'
        : 'Asignar colegiatura';
    },
    userInfoText() {
      const tutorText =
        this.usernameTutor !== '' && this.passwordTutor !== ''
          ? `Usuario tutor: ${this.usernameTutor}, Contraseña tutor: ${this.passwordTutor}`
          : '';
      const studentText =
        this.usernameStudent !== '' && this.passwordStudent !== ''
          ? `Usuario alumno: ${this.usernameStudent}, Contraseña alumno: ${this.passwordStudent}`
          : '';
      return `${tutorText} ${studentText}`;
    },
    allowedGroupActions() {
      return this.groupActions.filter((action) => action.hasPermissions());
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (to.name !== 'Nuevo Alumno' && to.params.id) {
        try {
          vm.setLoadingState(true, 'Cargando datos del estudiante, por favor espere...', 'info');
          await vm.loadStudentData(to.params.id);
        } catch (error) {
          console.error('Error al cargar los datos del estudiante en beforeRouteEnter:', error);
        } finally {
          vm.setLoadingState(false);
        }
      }
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name !== 'Nuevo Alumno' && to.params.id !== from.params.id) {
      this.setLoadingState(true, 'Actualizando datos del estudiante, por favor espere...', 'info');

      this.loadStudentData(to.params.id)
        .then(() => { })
        .catch(error => {
          console.error('Error al actualizar los datos del estudiante en beforeRouteUpdate:', error);
          this.setErrorState('Error al procesar los datos. Por favor, inténtelo de nuevo.');
        });
    }
    next();
  },


  async created() {
    try {
      this.setLoadingState(true, 'Cargando datos por favor espere...', 'info');

      const tasks = [];

      if (this.$route.params.id) {
        tasks.push(
          this.studentContructor(this.$route.params.id, '', this.institutionId)
            .then(student => {
              this.currentStudent = student;
              this.studentId = student.id;
              this.initializeStudentData(student);
              this.initialTutorInfo = { ...this.tutorPersonalInfo };
              this.initialStudentInfo = { ...this.studentPersonalInfo };
            })
            .catch(err => {
              console.error('Error al cargar datos del estudiante:', err);
              throw new Error('No se pudo cargar el estudiante.');
            })
        );
      }

      const { password_facturama, usuario_facturama } = await this.currentInstitution
      this.tutorTaxInfo = await this.fetchDefaultTaxInfo({
        user: usuario_facturama,
        password: password_facturama,
        institutionId: this.institutionId
      });
      
      tasks.push(
        this.loadDataStudentForm().catch(err => {
          console.error('Error al cargar datos del formulario:', err);
          throw new Error('No se pudo cargar el formulario.');
        })
      );

      await Promise.all(tasks);
      this.studentPersonalInfo.institucion_educativa = this.institutionId;

    } catch (error) {
      console.error('Error fetching data:', error);
      this.setErrorState(true, error.message || 'Error inesperado al cargar los datos.');
    } finally {
      this.loading = false;
      this.showAlert = false;
    }
  },

  methods: {
    ...mapActions('facturama', ['fetchDefaultTaxInfo']),
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'info';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion


    async loadStudentData(studentId) {
      try {
        this.setLoadingState(true, 'Cargando datos del estudiante, por favor espere...', 'info');
        const student = await this.studentContructor(studentId, '', this.institutionId);
        this.currentStudent = student;
        this.studentId = student.id;
        this.initializeStudentData(student);
        this.initialTutorInfo = { ...this.tutorPersonalInfo };
        this.initialStudentInfo = { ...this.studentPersonalInfo };
      } catch (error) {
        console.error('Error al cargar los datos del estudiante:', error);
        throw error;
      }
    },
    getItemText(tutor) {
      const { primer_nombre, segundo_nombre, apellido_paterno, apellido_materno } = tutor.datos_personales;
      return [primer_nombre, segundo_nombre, apellido_paterno, apellido_materno]
        .filter(Boolean)
        .join(' ')
        .trim();
    },


    async applyGenericInfo() {
      const { password_facturama, usuario_facturama } = await this.currentInstitution
      const genericTaxInfo = await this.fetchDefaultTaxInfo({
        user: usuario_facturama,
        password: password_facturama,
        institutionId: this.institutionId
      });
      

      this.$emit('update-tax-info', {
        taxInfo: genericTaxInfo,
        regimentTypes: this.regimentTypes,
        invoiceUse: this.invoiceUse,
      });
    },

    async loadDataStudentForm() {
      const [resTutors, resGroups] = await Promise.all([
        getItem(
          `/app-personas/filters/tutor?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=2000`
        ),
        getItem(
          `/app-personas/filters/grupo?institucion_educativa=${this.institutionId}&is_especial=false&estatus_sistema=true&ciclo_escolar=all&limit=1000`
        ),
      ]);

      this.tutorsArr = resTutors.results;
      this.groups = resGroups.results;
    },

    async loadTutors() {
      try {
        const resTutors = await getItem(`/app-personas/filters/tutor?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=2000`)
        this.tutorsArr = resTutors.results
      } catch (error) {
        console.error('Error al cargar los tutores:', error);
      }
    },


    handleCheckboxUpdate(isChecked) {
      if (isChecked) {
        this.backupStudentAddress = { ...this.studentAddress };
        this.studentAddress = { ...this.tutorAddress };
      } else {
        if (this.backupStudentAddress) {
          this.studentAddress = { ...this.backupStudentAddress };
        } else {
          this.studentAddress = this.getDefaultStudentAddress();
        }
      }
      this.isAddressCheckboxChecked = isChecked;
    },

    getDefaultStudentAddress() {
      return {
        id: null,
        calle: '',
        numero_contacto: '',
      };
    },


    //#region CRUD Alumnos
    async createStudent() {
      try {
        this.alertText = 'Creando alumno...';

        if (this.isAddressCheckboxChecked) {
          this.studentAddress = { ...this.tutorAddress, institucion_educativa: this.institutionId };
        } else {
          this.studentAddress.institucion_educativa = this.institutionId;
          const newAddressId = await this.createOrUpdateStudentAddress();
          this.studentAddress.id = newAddressId;
        }

        this.studentPersonalInfo.direccion = this.studentAddress.id;
        this.studentPersonalInfo.institucion_educativa = this.institutionId;
        this.studentPersonalInfo.email = this.tutorPersonalInfo.email;
        this.studentPersonalInfo.telefono_casa = this.tutorPersonalInfo.telefono_movil;
        this.studentPersonalInfo.telefono_movil = this.tutorPersonalInfo.telefono_movil;

        this.alertText = 'Creando datos personales de alumno';
        await this.postPersonalInfo(this.studentPersonalInfo);

        if (!this.studentPersonalInfo.id) {
          return this.errors.push('Error al intentar crear datos personales del alumno');
        }

        const userObj = {
          nombre_usuario: this.generateUsername(this.studentPersonalInfo),
          password: this.generatePassword(this.studentPersonalInfo),
          correo: this.tutorPersonalInfo.email,
          nombre: this.studentPersonalInfo.primer_nombre,
          apellido_paterno: this.studentPersonalInfo.apellido_paterno,
          is_staff: false,
        };

        this.alertText = 'Creando usuario de alumno';
        await this.postUser(userObj);
        this.usernameStudent = userObj.nombre_usuario;
        this.passwordStudent = userObj.password;

        const studentObj = {
          usuario: userObj.id,
          curp: this.studentPersonalInfo.numero_identidad_curp,
          datos_personales: this.studentPersonalInfo.id,
          institucion_educativa: this.institutionId,
          numero_credencial_alumno: this.studentPersonalInfo.numero_credencial_alumno,
          nivel_educativo: this.studentPersonalInfo.nivel_educativo,
          tutores: [this.tutorId],
        };

        const student = await postStudent(studentObj);
        this.studentId = student.data.id;

      } catch (error) {
        this.errors.push('Error al intentar crear alumno');
        console.error('Error al intentar crear alumno.', error);
      }
    },
    async createTutor() {
      try {
        // Verifica si ya existe un tutor
        if (!this.tutorId) {
          this.alertText = 'Creando tutor...';
          this.tutorAddress.numero_contacto = this.tutorPersonalInfo.telefono_movil;
          this.tutorAddress.institucion_educativa = this.institutionId;

          this.alertText = 'Creando dirección de tutor...';
          await this.postAddress(this.tutorAddress);

          this.tutorPersonalInfo.institucion_educativa = this.institutionId;
          this.tutorPersonalInfo.telefono_casa = this.tutorPersonalInfo.telefono_movil;

          this.alertText = 'Creando tutor...';

          const registerTutor = await this.registerTutor();
          if (!registerTutor || !registerTutor.id) throw new Error('No se pudo crear el tutor.');
          this.tutorId = registerTutor.id;

          await this.loadTutors();
          this.alertText = `Usuario tutor: ${registerTutor.usuario.username} creado exitosamente`;

          return registerTutor;

        }
      } catch (error) {
        console.error('Error al intentar crear tutor:', error);

        if (this.tutorAddress.id) {
          this.alertText = 'Error en el proceso, eliminando dirección creada...';
          try {
            await deleteAddress(this.tutorAddress.id);
            console.log('Dirección eliminada exitosamente debido a error en la creación del tutor.');
          } catch (deleteError) {
            console.error('Error al eliminar la dirección tras fallo en la creación del tutor:', deleteError);
          }
        }

        this.errors.push('Error al intentar crear tutor. Verifique los datos e intente nuevamente.');
        this.setErrorState('Error al crear el tutor, por favor revise los datos.');
        return null;
      }
    },
    async registerTutor() {
      try {
        this.usernameTutor = this.tutorPersonalInfo.email;
        this.passwordTutor = this.generatePassword(this.tutorPersonalInfo);

        this.alertText = 'Obteniendo información fiscal del tutor...';
        const tutorTaxInfoId = await this.manageTaxInfo();

        const userType = await this.getTypeTutor();
        console.log(this.tutorTaxInfo.uso_factura);

        const { res: invoiceUsage } = await fetchInvoiceUsage(this.tutorTaxInfo.uso_factura.id);
        const { res, ok } = await fetchPostRegisterTutor({
          uso_factura: invoiceUsage.clave,
          id_institucion_educativa: this.institutionId,
          primer_nombre: this.tutorPersonalInfo.primer_nombre,
          apellido_paterno: this.tutorPersonalInfo.apellido_paterno,
          password: this.passwordTutor,
          email: this.tutorPersonalInfo.email,
          segundo_nombre: this.tutorPersonalInfo.segundo_nombre,
          apellido_materno: this.tutorPersonalInfo.apellido_materno,
          fecha_nacimiento: this.tutorPersonalInfo.fecha_nacimiento,
          edad: this.tutorPersonalInfo.edad,
          id_direccion: this.tutorAddress.id,
          tax_id: tutorTaxInfoId,
          id_parentesco: this.tutorPersonalInfo.parentesco,
          id_genero: this.tutorPersonalInfo.genero,
          numero_identidad_CURP: this.tutorPersonalInfo.numero_identidad_curp,
          telefono_casa: this.tutorPersonalInfo.telefono_casa,
          telefono_movil: this.tutorPersonalInfo.telefono_movil,
          regimen_fiscal: this.tutorTaxInfo.regimen_fiscal.Value,
          moneda: this.tutorTaxInfo.moneda,
          lugar_expedicion: this.tutorTaxInfo.lugar_expedicion,
          tipo_cfdi: this.tutorTaxInfo.tipo_cfdi,
          condiciones_pago: this.tutorTaxInfo.condiciones_pago,
          forma_pago: this.tutorTaxInfo.forma_pago,
          metodo_pago: this.tutorTaxInfo.metodo_pago,
          id_tipo_usuario: userType.id
        });

        if (!ok) {
          throw new Error('Error al intentar registrar tutor.');
        }

        if (res.error || res.e) {
          throw new Error(res.error || res.e);
        }

        return res;

      } catch (error) {
        console.error('Error al intentar registrar tutor:', error);
        this.errors.push('Error al intentar registrar tutor. Verifique los datos e intente nuevamente.');
        return null;
      }
    },
    async getTypeTutor() {
      const userRes = await getItem('app-land/filters/tipo-usuario?tipo_usuario=Tutor&estatus_sistema=true')
      const user = userRes.results[0]
      return user
    },
    async updateTutor() {
      try {
        this.alertText = 'Actualizar tutor...';
        // this.tutorId = this.tutor.id;
        // const tutorAddress = await getItem(
        //   `/app-personas/direccion/${this.tutorPersonalInfo.direccion}`
        // );
        // this.tutorAddress = { ...tutorAddress };
        // this.tutorAddress.codigo_postal = tutorAddress.codigo_postal.id;
        this.tutorAddress.numero_contacto =
          this.tutorPersonalInfo.telefono_movil;
        this.tutorAddress.institucion_educativa = this.institutionId;

        this.alertText = 'Actualizar dirección de tutor...';
        await this.updateAddress(this.tutorAddress, this.tutorAddress.id);
        this.tutorPersonalInfo.id_institucion_educativa = this.institutionId;
        this.tutorPersonalInfo.id_direccion = this.tutorAddress.id;
        this.tutorPersonalInfo.telefono_casa =
          this.tutorPersonalInfo.telefono_movil;

        this.alertText = 'Actualizando datos personales de tutor...';
        await this.updatePersonalInfo(
          this.tutorPersonalInfo,
          this.tutorPersonalInfo.id
        );

        this.alertText = 'Actualizando datos fiscales de tutor...';
        await this.manageTaxInfo()
        const tutorObj = {
          id: this.tutorId,
          usuario: this.tutor.usuario.id,
          datos_personales: this.tutorPersonalInfo.id,
          datos_fiscales: [this.tutorTaxInfo.id],
          responsable_economico: true,
          institucion_educativa: this.institutionId,
          parentesco: this.tutorPersonalInfo.parentesco.id,
        };
        await putTutor(tutorObj, tutorObj.id);
      } catch (error) {
        this.errors.push('Error al intentar actualizar tutor');
        console.error('Error al intentar actualizar tutor.', error);
      }
    },

    async manageTaxInfo() {
      try {
        const { useGenericInfo, tutorTaxInfo, institutionId, currentInstitution } = this;
        const getGenericTaxInfo = async () => {
          const { password_facturama, usuario_facturama } = await this.currentInstitution
          const genericTaxInfo = await this.fetchDefaultTaxInfo({
            user: usuario_facturama,
            password: password_facturama,
            institutionId: institutionId
          });
          
          return {
            ...genericTaxInfo,
            uso_factura: genericTaxInfo.uso_factura.id,
            regimen_fiscal: genericTaxInfo.regimen_fiscal.Value,
            lugar_expedicion: currentInstitution.datos_fiscales.lugar_expedicion
          };
        };

        if (useGenericInfo && !tutorTaxInfo?.id) {
          const taxInfo = await getGenericTaxInfo();
          await this.postTaxInfo(taxInfo);
          this.tutorTaxInfo.id = taxInfo.id;
          return taxInfo.id
        }

        if (useGenericInfo) {
          const taxInfo = await getGenericTaxInfo();
          await this.updateTaxInfo(taxInfo, this.tutorTaxInfo.id);
          return this.tutorTaxInfo.id;
        }

        if (!tutorTaxInfo?.id) {
          throw new Error("No se puede gestionar la información fiscal del tutor. Datos incompletos.");
        }

        await this.updateTaxInfo(tutorTaxInfo, tutorTaxInfo.id);
        return tutorTaxInfo.id;

      } catch (error) {
        console.error("Error al gestionar la información fiscal:", error);
        throw error;
      }
    },

    async updateStudent() {
      try {
        this.alertText = 'Actualizando alumno...';

        if (!this.isAddressCheckboxChecked) {
          await this.handleStudentAddress();
        }

        await this.updateStudentPersonalInfo();
        await this.updateStudentInfo();

      } catch (error) {
        this.errors.push('Error al intentar actualizar alumno');
        console.error('Error al intentar actualizar alumno.', error);
      }
    },

    async handleStudentAddress() {
      this.alertText = 'Creando nueva dirección de alumno...';
      this.studentAddress.numero_contacto = this.tutorPersonalInfo.telefono_movil;
      this.studentAddress.institucion_educativa = this.institutionId;

      await this.postAddress(this.studentAddress);

      if (!this.studentAddress.id) {
        throw new Error('Error al intentar crear la nueva dirección de alumno.');
      }
    },

    async updateStudentPersonalInfo() {
      this.studentPersonalInfo.direccion = this.studentAddress.id;
      this.studentPersonalInfo.institucion_educativa = this.institutionId;
      this.studentPersonalInfo.email = this.tutorPersonalInfo.email;
      this.studentPersonalInfo.telefono_casa = this.tutorPersonalInfo.telefono_movil;
      this.studentPersonalInfo.telefono_movil = this.tutorPersonalInfo.telefono_movil;

      this.alertText = 'Actualizando datos personales de alumno...';
      await this.updatePersonalInfo(this.studentPersonalInfo, this.studentPersonalInfo.id);
    },

    async updateStudentInfo() {
      const studentObj = {
        alumno: this.studentId,
        usuario: this.currentStudent.usuario.id,
        numero_credencial_alumno: this.studentPersonalInfo.numero_credencial_alumno,
        nivel_educativo: this.studentPersonalInfo.nivel_educativo,
        datos_personales: this.studentPersonalInfo.id,
        institucion_educativa: this.institutionId,
        colegiatura: this.studentPersonalInfo.colegiatura.id,
        tutores: [this.tutorId],
      };

      this.alertText = 'Actualizando información del alumno...';
      await putStudent(studentObj, this.studentId);
    },
    //#endregion

    objectsAreEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },

    async updateIfChanged(currentData, originalData, updateFn, identifier = '') {
      if (!this.objectsAreEqual(currentData, originalData)) {
        this.alertText = `Actualizando ${identifier}...`;
        await updateFn(currentData, currentData.id);
      }
    },

    async handleTutorCreationOrUpdate() {
      if (this.isNewTutor) {
        try {
          const tutor = await this.createTutor();
          if (tutor && tutor.id) {
            if (!this.isNewMode) {
              await this.updateStudentInfo();
            }
          }
        } catch (error) {
          console.error('Error al crear o actualizar el tutor:', error);
          this.errors.push('Error al crear o actualizar el tutor.');
        }
      } else {
        try {
          const updatedTutorInfo = {
            ...this.tutorPersonalInfo,
            direccion: this.tutorAddress.id,
            institucion_educativa: this.institutionId,
          };
          await this.updateIfChanged(updatedTutorInfo, this.initialTutorInfo, this.updateTutor, 'tutor');
        } catch (error) {
          console.error('Error al actualizar el tutor:', error);
          this.errors.push('Error al actualizar el tutor.');
        }
      }
    },

    async handleStudentCreationOrUpdate() {
      if (this.isNewMode) {
        await this.createStudent();
      } else {
        const updatedStudentInfo = {
          ...this.studentPersonalInfo,
          direccion: this.studentAddress.id,
          institucion_educativa: this.institutionId,
        };
        await this.updateIfChanged(updatedStudentInfo, this.initialStudentInfo, this.updateStudent, 'alumno');
      }
    },

    async handleAddressCreationOrUpdate(addressData, isNewAddress) {
      try {
        if (isNewAddress) {
          this.alertText = 'Creando dirección...';
          const createdAddress = await this.postAddress(addressData);
          if (!createdAddress.id) {
            throw new Error('Error al intentar crear dirección');
          }
          return createdAddress.id;
        } else {
          if (!addressData.id) {
            throw new Error('El ID de la dirección no está definido.');
          }
          console.log({ addressData });
          const updatedAddress = await this.updateAddress(addressData, addressData.id);
          if (!updatedAddress || !updatedAddress.id) {
            throw new Error('Error al actualizar la dirección. Respuesta inválida.');
          }

          return updatedAddress.id;
        }
      } catch (error) {
        console.error('Error al intentar actualizar/crear dirección:', error);
        this.errors.push('Error al intentar actualizar/crear dirección.');
      }
    },

    async createOrUpdateTutorAddress() {
      const isNewAddress = !this.tutorAddress.id;
      return await this.handleAddressCreationOrUpdate(this.tutorAddress, isNewAddress);
    },

    async createOrUpdateStudentAddress() {
      const isNewAddress = !this.studentAddress.id;
      return await this.handleAddressCreationOrUpdate(this.studentAddress, isNewAddress);
    },
    async assignGroupIfNoErrors() {
      try {
        const groupId = this.selectedGroup.id;
        const studentDetailsPayload = this.getStudentDetailsPayload();

        await this.assignGroup(groupId, studentDetailsPayload);

        if (this.checkInitialDebts) {
          await this.createDefaultDebits();
        }
      } catch (error) {
        this.errors.push('Error al asignar el grupo');
        console.error('Error al asignar el grupo.', error);
      }
    },

    async createObjects() {
      this.setLoadingState(true, 'Procesando...', 'info');
      try {
        const tuitionId = this.studentPersonalInfo?.colegiatura?.id || null;
        await this.handleTutorCreationOrUpdate();

        if (this.errors.length)
          return this.setErrorState('Error al procesar los datos del tutor. Por favor, inténtelo de nuevo.');
        await this.handleStudentCreationOrUpdate();

        if (this.errors.length)
          return this.setErrorState('Error al procesar los datos del alumno. Por favor, inténtelo de nuevo.');

        if (this.selectedGroupAction === ACTIONS_GROUP.ASSIGN)
          await this.assignGroupIfNoErrors();

        if (tuitionId) {
          await this.handleTuition('', tuitionId);

        }
        this.setSuccessState(`Alumno y tutor procesados con éxito. ${this.userInfoText}`);
      } catch (error) {
        this.errors.push('Error al procesar los datos');
        console.error('Error al procesar los datos:', error);
        this.setErrorState('Error al procesar los datos. Por favor, inténtelo de nuevo.');
      }
    },

    async updateObjects() {
      this.setLoadingState(true, 'Actualizando datos...', 'info');
      try {
        await this.handleTutorCreationOrUpdate();
        await this.handleStudentCreationOrUpdate();

        const groupId = this.selectedGroup?.id || null;
        const bkGroupId = this.currentStudent?.groupId || null;
        const bkTuitionId = this.currentStudent?.colegiatura?.id || null;
        const tuitionId = this.studentPersonalInfo?.colegiatura?.id || null;

        const studentDetailsPayload = this.getStudentDetailsPayload();

        await this.handleGroupAssignment(bkGroupId, groupId, studentDetailsPayload);
        await this.handleTuition(bkTuitionId, tuitionId);

        if (this.errors.length) {
          this.setErrorState('Error al actualizar los datos del alumno. Por favor, inténtelo de nuevo.');
        } else {
          this.setSuccessState('Datos actualizados correctamente');
        }
      } catch (error) {
        this.errors.push('Error al actualizar los datos');
        console.error('Error al actualizar los datos:', error);
        this.setErrorState('Error al actualizar los datos. Por favor, revise los datos e intente nuevamente.');
      }
    },


    async handleGroupAssignment(oldGroupId, newGroupId, studentDetailsPayload) {
      try {
        switch (this.selectedGroupAction) {
          case ACTIONS_GROUP.ASSIGN:
            await this.assignGroup(newGroupId, studentDetailsPayload);
            break;
          case ACTIONS_GROUP.PASS:
            await this.reAssignGroupForNewSchoolYear(oldGroupId, newGroupId);
            break;
          case ACTIONS_GROUP.UNASSIGN:
            await handleStudentGroupChange(this.studentId, oldGroupId, null, 'Desasignacion de alumno');
            break;
          default:
            console.info('Nada por hacer');
        }
      } catch (error) {
        console.error('Error en handleGroupAssignment:', error);
        this.errors.push('Error en el proceso de asignación de grupo');
      }
    },
    async handleTuition(oldTuitionId, newTuitionId) {
      if (!newTuitionId) return;

      const currentTuition = this.isNewMode ? this.studentPersonalInfo.colegiatura : this.currentStudent?.colegiatura;
      const tuitionAction = this.isNewMode || !currentTuition ? 'asignar' : 'actualizar';

      try {
        if (tuitionAction === 'asignar') {
          await this.assignTuition(newTuitionId);
          // await this.getDefaultDebits();
        } else {
          if (!this.shouldUpdateTuition(oldTuitionId, newTuitionId)) return;
          await this.updateTuition(oldTuitionId, newTuitionId);
        }

        console.log(`Colegiatura ${tuitionAction} correctamente.`);
      } catch (error) {
        console.error(`Error al ${tuitionAction} la colegiatura:`, error);
        this.errors.push(`Error al ${tuitionAction} la colegiatura.`);
      }
    },

    async assignTuition(newTuitionId) {
      const tuition = await fetchPostAssignmentTuition(this.studentId, newTuitionId)
      const debit = await calculateDebtsObject(
        this.studentPersonalInfo,
        this.studentId,
        this.tuitionStartDate,
        this.tuitionEndDate,
        this.userData.id
      )
      const status = await this.getOrCreateStatusDebit(
        BASE_FINANCIAL_STATUS.DEBIT_STATUS,
        this.userData.id,
        this.institutionId
      )
      const dbts = await generateSchoolYearDebts({
        ...debit,
        id_institucion_educativa: this.institutionId,
        id_estatus_adeudo: status.id,
        id_descuento_pronto_pago: this.studentPersonalInfo.prontoPago.id,
        id_penalizacion: this.studentPersonalInfo.id_penalizacion
      })
      console.log(tuition, dbts);
      if (this.checkInitialDebts) {
        await this.createDefaultDebits();
      }
    },

    async updateTuition(oldTuitionId, newTuitionId) {
      if (this.shouldUpdateTuition(oldTuitionId, newTuitionId)) {
        await this.updateTuitionDebits(newTuitionId);
      }
    },
    async reAssignGroup(studentId, oldGroupId, newGroupId,) {
      try {
        if (oldGroupId !== newGroupId) {
          await handleStudentGroupChange(studentId, oldGroupId, newGroupId, 'Reasignacion de alumno');
          const studentDetailsPayload = this.getStudentDetailsPayload();
          await this.assignGroup(newGroupId, studentDetailsPayload);
        }

      } catch (error) {
        console.error('Error al intentar reasignar grupo del alumno:', error);
      }
    },

    async updateTuitionDebits(newTuitionId) {
      const updateTuition = await fetchPostUpdateChangeTuition(this.studentId, newTuitionId)
      console.log(updateTuition);
    },

    async reAssignGroupForNewSchoolYear(oldGroupId, newGroupId) {
      try {
        await handleStudentGroupChange(this.studentId, oldGroupId, newGroupId, 'Nuevo ciclo escolar');
        const studentDetailsPayload = this.getStudentDetailsPayload();
        await this.assignGroup(newGroupId, studentDetailsPayload);

      } catch (error) {
        console.error('Error al intentar reasignar grupo y colegiaturas del alumno:', error);
      }
    },

    async createDefaultDebits() {
      this.wildcardStudentDebts.forEach(async debit => {
        const { res } = await fetchPostDebit(debit, this.studentId, this.institutionId, this.userData.id);
        if (res.e) console.log('Ocurrió un error al crear adeudo');
      });
    },

    getStudentDetailsPayload() {
      return [this.studentId];
    },

    shouldUpdateTuition(oldTuitionId, newTuitionId) {
      return oldTuitionId && newTuitionId && newTuitionId !== oldTuitionId;
    },

    async assignGroup(groupId, studentDetailsPayload) {
      try {
        const previousGroupId = this.studentPersonalInfo.previousGroupId || null;
        if (previousGroupId !== groupId) {
          const startDate = this.selectedGroup.ciclo_escolar.fecha_inicio;
          const endDate = this.selectedGroup.ciclo_escolar.fecha_fin;

          await fetchPostAssignStudentsToGroup(
            groupId,
            this.studentPersonalInfo.plan_estudios.id,
            studentDetailsPayload,
            startDate,
            endDate
          );

        }
      } catch (error) {
        console.error('Error in assignGroup:', error.message);
      }
    },
    //#endregion

    async action3(value) {
      console.log('Mover todos', value);
      this.currentGroup = value
    },
    closeModal() {
      this.showUnenrollModal = false
    },
    handleStudentUnenroll() {
      console.log('abriendo modal');
      this.showUnenrollModal = true
    },
    setTabValue(val) {
      if (this.valueDeterminate !== val) {
        this.valueDeterminate = val;
        this.$emit('tabValue', val);
      }
    },
    async save() {
      try {
        if (this.isNewMode) {
          if (canAdd(this.resource)) {
            await this.createObjects();
          } else {
            this.insufficientPermissionAlert();
          }
        } else {
          if (canChange(this.resource)) {
            await this.updateObjects();
          } else {
            this.insufficientPermissionAlert();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.returnToTable();
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    async clearTutor() {
      await this.clearTutorData()
    },
    async clean() {
      if (this.$route.name !== 'Nuevo Alumno') {
        this.$router.push({ name: 'Nuevo Alumno' });
      }

      this.clearStudentData()
      await this.clearTutorData()
      this.defaultInfoForm()
    },
    defaultInfoForm() {
      this.setTabValue(33)
      this.showGroupActions = false
      this.selectedGroup = null;
      this.useGenericInfo = true;
      this.studentId = undefined;
      this.currentStudent = null;
      this.selectedGroupAction = null;
      this.openTuition = false
    },
    fillExample() {
      const tutorAddress = {
        estado: "Puebla",
        ciudad: "Heroica Puebla de Zaragoza",
        municipio: "Puebla",
        codigo_postal: 2,
        colonia: "Bosques San Sebastián",
        calle: "Sin calle",
        numero_exterior: "12", // Puedes cambiar este número si se desea
        numero_interior: "",
        numero_contacto: this.generateRandomPhone(), // Teléfono generado aleatoriamente
        code: "72310",
        institucion_educativa: 2,
        id: 91,
      };

      // Información personal del tutor con nombres aleatorios
      const tutorPersonalInfo = {
        curp: null,
        primer_nombre: this.generateRandomFirstName(), // Nombre generado aleatoriamente
        segundo_nombre: "",
        apellido_paterno: this.generateRandomLastName(), // Apellido generado aleatoriamente
        apellido_materno: this.generateRandomLastName(), // Apellido generado aleatoriamente
        genero: 1,
        fecha_nacimiento: "1998-02-03",
        edad: 26,
        numero_identidad: "",
        telefono_movil: this.generateRandomPhone(), // Teléfono generado aleatoriamente
        email: this.generateRandomEmail("tutor"), // Email generado aleatoriamente
        numero_identidad_CURP: "EXAMPLE3485974",
        parentesco: 1,
        responsable_economico: true,
        institucion_educativa: 2,
        direccion: tutorAddress.id, // Usar la dirección compartida
        telefono_casa: this.generateRandomPhone(), // Teléfono generado aleatoriamente
      };

      // Información personal del estudiante con nombres aleatorios
      const studentPersonalInfo = {
        primer_nombre: this.generateRandomFirstName(), // Nombre generado aleatoriamente
        segundo_nombre: "",
        apellido_paterno: this.generateRandomLastName(), // Apellido generado aleatoriamente
        apellido_materno: this.generateRandomLastName(), // Apellido generado aleatoriamente
        genero: 1,
        fecha_nacimiento: "2009-06-13",
        edad: 15,
        numero_identidad: "",
        institucion_educativa: 2,
        numero_credencial_alumno: "",
        nivel_educativo: 6,
        curp: null,
        dateRange: {
          from: null,
          to: null,
        },
        numero_identidad_CURP: "ASDASD9078",
        direccion: tutorAddress.id, // Usar la dirección compartida
        email: this.generateRandomEmail("student"), // Email generado aleatoriamente
        telefono_casa: this.generateRandomPhone(), // Teléfono generado aleatoriamente
        telefono_movil: this.generateRandomPhone(), // Teléfono generado aleatoriamente
        id: 137,
      };
      console.log(tutorAddress, tutorPersonalInfo, studentPersonalInfo);
      this.tutorAddress = tutorAddress
      this.tutorPersonalInfo = tutorPersonalInfo
      this.studentPersonalInfo = studentPersonalInfo
      // Retornar los datos completos
      return {
        tutorAddress,
        tutorPersonalInfo,
        studentPersonalInfo,
      };
    },


    generateRandomFirstName() {
      const firstNames = [
        'Juan', 'Carlos', 'María', 'Ana', 'Pedro', 'Luisa', 'Diego', 'Sofía', 'Pablo', 'Camila',
        'Javier', 'Beatriz', 'Miguel', 'Isabel', 'Raúl', 'Marta', 'Fernando', 'Carmen', 'Andrés', 'Verónica'
      ];
      return firstNames[Math.floor(Math.random() * firstNames.length)];
    },

    generateRandomLastName() {
      const lastNames = [
        'García', 'Martínez', 'Rodríguez', 'López', 'Hernández', 'Pérez', 'Gómez', 'Sánchez', 'Ramírez', 'Flores',
        'Jiménez', 'Torres', 'Díaz', 'Vázquez', 'Cruz', 'Moreno', 'Romero', 'Ortiz', 'Reyes', 'Aguilar'
      ];
      return lastNames[Math.floor(Math.random() * lastNames.length)];
    },

    generateRandomPhone() {
      const prefix = "3";
      const randomPhone = Math.floor(1000000000 + Math.random() * 900000000).toString();
      return prefix + randomPhone.slice(1);
    },

    generateRandomEmail(baseName) {
      const randomNum = Math.floor(Math.random() * 10000);
      return `${baseName}${randomNum}@example.com`;
    },


    clearStudentData() {
      this.studentPersonalInfo.grupo = null;

      this.studentPersonalInfo = {
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad: '',
        institucion_educativa: null,
        numero_credencial_alumno: '',
        nivel_educativo: '',
        curp: null,
        dateRange: {
          from: null,
          to: null,
        },
      };

      this.studentAddress = {
        estado: null,
        ciudad: '',
        municipio: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      };

      this.tuitionStartDate = undefined;
      this.tuitionEndDate = undefined;
    },

    async clearTutorData() {
      this.tutorId = undefined;
      this.isNewTutor = true;
      this.tutor = null;
      this.originalTaxInfo = null

      this.tutorPersonalInfo = {
        curp: null,
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad: '',
        telefono_movil: null,
        email: null,
      };

      this.tutorAddress = {
        estado: null,
        ciudad: '',
        municipio: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      };
      this.tutorTaxInfo = await this.fetchDefaultTaxInfo({
        user: this.currentInstitution.usuario_facturama,
        password: this.currentInstitution.password_facturama,
        institutionId: this.institutionId
      });      
    },

    calculateTaxes(value, tax) {
      return value * tax;
    },
    calculateDiscount(value, discount) {
      return value * discount;
    },
    calculateTotalWithTaxes(value, taxes) {
      return value + taxes;
    },
    calculateTotalWithDiscount(value, discount) {
      return value - discount;
    },
    async fillTutor() {
      this.loadingTutor = true
      try {
        if (!this.tutor) return
        const tutorData = await buildStudentTutor(this.tutor);
        this.tutorPersonalInfo = tutorData.personalInfo;
        this.tutorAddress = tutorData.address;
        this.tutorTaxInfo = tutorData.taxInfo;
        this.originalTaxInfo = tutorData.taxInfo
        this.tutorId = this.tutor.id;
        this.isEditing = false
        this.isNewTutor = false
        if (!this.tutorTaxInfo.id || this.isNewMode) {
          this.handleCheckTaxInfo(true)

        }
        else this.handleCheckTaxInfo(false)
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTutor = false
      }
    },
    async addTutor() {
      this.tutorPersonalInfo = {
        responsable_economico: false,
        genero: '',
        parentesco: '',
        edad: undefined,
        numero_identidad_CURP: ''
      };

      this.tutorAddress = {
        codigo_postal: 0,
        code: '',
        municipio: ''
      };

      this.useGenericInfo = true;

      this.tutorTaxInfo = await this.fetchDefaultTaxInfo({
        user: this.currentInstitution.usuario_facturama,
        password: this.currentInstitution.password_facturama,
        institutionId: this.institutionId
      });      

      this.tutor = null
      this.tutorId = null;
      this.loadingTutor = false;
      this.isNewTutor = true;

    },


    async initializeStudentData(currentStudent) {
      try {
        const { datos_personales, groupId } = currentStudent;

        const [studentPersonalInfo, studentAddress, selectedGroup, tutor] = await Promise.all([
          buildStudentPersonalInfo(currentStudent),
          buildStudentAddress(datos_personales.direccion),
          biuldStudentGroup(groupId),
          findTutor(currentStudent),
        ]);

        this.studentPersonalInfo = studentPersonalInfo;
        this.studentAddress = studentAddress;
        this.selectedGroup = selectedGroup;
        this.tutor = tutor;

        if (tutor) await this.fillTutor();
      } catch (error) {
        console.error('Error initializing student data:', error);
      }
    },

    async fillGroupRelatedArrays() {
      if (
        this.tuition.length &&
        this.promptPaymentDiscounts.length &&
        this.penalties.length &&
        this.debtStatus.length
      ) {
        return;
      }
      try {
        const requests = [
          getItem(`/app-personas/filters/colegiatura?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
          getItem(`/app-administracion/filters/descuento-pronto-pago?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
          getItem(`/app-administracion/filters/penalizacion?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
          getItem(`/app-administracion/filters/estatus-adeudos?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
          getItem(`/app-personas/filters/ciclo-escolar?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        ];
        const [
          tuitionRes,
          promptPaymentDiscountsRes,
          penaltiesRes,
          debtStatusRes,
          schoolsYearArrRes
        ] = await Promise.all(requests);

        this.tuition = tuitionRes.results;
        this.promptPaymentDiscounts = promptPaymentDiscountsRes.results;
        this.penalties = penaltiesRes.results;
        this.debtStatus = debtStatusRes.results;
        this.schoolsYearArr = schoolsYearArrRes.results
      } catch (error) {
        console.error('Error al intentar llenar los arrays relacionados a grupo:', error);
      }
    },
    fillTuition() {
      this.tuitionStartDate = this.schoolYear.fecha_inicio
      this.tuitionEndDate = this.schoolYear.fecha_fin
    },

    async loadGroupInfo() {
      this.loadingGroupInfo = true;
      try {
        if (!this.selectedGroup || !this.selectedGroup.ciclo_escolar) {
          return
        }

        let studyPlans = [];
        const firstRequest = await getItem(
          `app-personas/filters/plan-estudios?ciclo_escolar=${this.selectedGroup.ciclo_escolar.id}&institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        );
        studyPlans = firstRequest.results;

        if (studyPlans.length) {
          this.studentPersonalInfo.plan_estudios = studyPlans[0];
        } else {
          const secondRequest = await getItem(
            `app-personas/filters/plan-estudios?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
          );
          studyPlans = secondRequest.results;
        }

        this.studyPlans = studyPlans;

      } catch (error) {
        console.error('Error al cargar la información del grupo:', error);
      } finally {
        this.loadingGroupInfo = false;
      }
    },
    async getDefaultDebits() {
      if (!this.selectedGroup || !this.selectedGroup.ciclo_escolar) {
        return
      }
      try {
        const comodinUser = await this.getOrCreateWildcard(this.institutionId, this.userData.id);
        if (!comodinUser) throw new Error('No se pudo obtener el usuario comodín');

        const debitUser = await postItem(
          '/app-administracion/helpers/get-adeudos-por-alumno-por-ciclo-escolar',
          {
            id_alumno: comodinUser.id,
            id_ciclo_escolar: this.selectedGroup.ciclo_escolar.id,
          }
        );
        this.wildcardStudentDebts = debitUser.resultado;
      } catch (error) {
        console.error('Error al obtener los adeudos por defecto:', error);
      }
    },

    async handleGroupActionChange() {
      console.log('metodo');
      this.loadingGroupChange = true;
      try {
        this.showGroupActions = true;
        console.log(this.selectedGroupAction, 'info');
        switch (this.selectedGroupAction) {
          case ACTIONS_GROUP.ASSIGN:
            await this.loadGroupInfo();
            break;
          case ACTIONS_GROUP.PASS:
            // case ACTIONS_GROUP.REASSIGN:
            await this.loadGroupInfo();
            break;
          case ACTIONS_GROUP.UNASSIGN:
            this.clearGroupSelection();
            this.showGroupActions = false;
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('Error al cambiar la acción del grupo:', error);
      } finally {
        this.loadingGroupChange = false;
      }
    },

    async handleGroupInfoChange() {
      console.log('metodo');
      if (this.selectedGroup) {
        this.showGroupActions = true;
        await this.loadGroupInfo()
      }

      if (!this.hasGroup)
        this.selectedGroupAction = ACTIONS_GROUP.ASSIGN
    },
    handleOpenTuition(value) {
      this.openTuition = value
      this.fillGroupRelatedArrays()
    },

    clearGroupSelection() {
      this.wildcardStudentDebts = [];
    },
    handleCheckTaxInfo(value) {
      this.useGenericInfo = value
    },
    handleUpdateTaxInfo({ taxInfo, regimentTypes, invoiceUse }) {
      this.tutorTaxInfo = taxInfo;
      this.regimentTypes = regimentTypes;
      this.invoiceUse = invoiceUse;
    },

  },
};
